.brand__mainPicture > img{
    margin-top: 25px;
    width: 100%;
    z-index: -1 !important;
    top: 65px;
    position: fixed !important;
    margin-top: -10px;
}

@media (max-width:500px){
    body {
      z-index: -1 !important;
      padding:0px;

    }
    .brand__mainPicture > img{

      width: 100%;
      z-index: -1 !important;
      margin-top: -10px;
      position: fixed !important;
      padding-bottom: -60px;
      height: 160px;
  }
  } 