@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Slider {
  width: 80%;
  margin: 10rem auto;
  height: 570px;
}
.slide img {
  width: 20rem;
  margin: 0 auto;
  border-radius: 25px;
}

.slide {
  padding: 30px;
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
  border-radius: 25px;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: none;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 400ms;

}

.arrow svg:hover {
  color: #619c1c;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
@media (max-width:500px){
   
  .Slider {
    height: 100vh; 
    width:  100vw;
    margin: 0px ;
    margin-bottom: -120px;

  }

  .slide img {
    width: 100%;
    padding: 0px;
    border-radius: 5px;

    }
  
  .slide {
    height: 500px;
    padding: 40px;
    display: none;
    opacity: 0;
    margin: 0px;
    padding-bottom: 60px;
    transition: transform 300ms;
  }
  
  .activeSlide {
    transform: scale(5.5);
    opacity: 1;
    margin-top: 250px;
    margin-bottom: 250px;
    align-items: center;
    height: 100%;
    padding: 0px;
  
  }
  
  .arrow {
    background-color: none;
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }
  
  .arrow svg {
    transition: color 400ms;
  }
  
  .arrow svg:hover {
    color: black;
  }
  
  .next {

    right: 0%;
    top: 50%;
  }
  
  .prev {

    left: 0%;
    top: 50%;
  }

}