.ConsentForms{
    background-color: #fafafa;
    text-align: center;
    padding: 10px 180px;
}

.ConsentForms__h1{
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    padding: 5px ;
}

.ConsentForm__span{
    font-weight: 600;
    text-align: center;
    

}

.btn{
    max-width:60%;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #202020;
    text-align: left;
}
.btn:hover{
    background-color: #fafafa;
    color: black;
    font-weight:bold;
}
.ConsentForms__button{
    /* margin: 60px 0px; */
    padding: 10px;
    background-color: #fafafa;
}

.ConsentForm__span__one{
    align-items: left;
    text-align:left;
}

@media (max-width:500px){

  .ConsentForms{
    background-color: #fafafa;
    width: 100%;
    padding: 0px 10px;
  }
    
h1{
    font-size:20px;
    text-align: center;
    font-weight: bold;
    margin: 5px 0px;
}



.btn{
    max-width: 100%;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #202020;
    text-align: left;
}
.btn:hover{
    background-color: #fafafa;
    color: black;
    font-weight:bold;
}
.ConsentForms__button{
    margin: 20px 0px;
    padding: 10px;
    background-color: #fafafa;
}

  }