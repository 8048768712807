.pricing{
    background-color: #fafafa;
    padding: 10px 30px;
    }

.pricing__h3{
    color:black;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;  
    text-align:left; 
}

.pricing__h3__1{
    color:black;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;  
    text-align:left; 

}
.pricing__col{
    width: 50%;
}
.pricing__paragraph{
opacity: 1!important;
padding: 10px;
padding-bottom: -10px;
display: block;
width: 60%;
padding-left: 180px;
text-align:left!important;
} 
.pricing__touchup{
    padding: 10px;
    padding-bottom: -10px;
    display: block;
  
    width: 100%;
    text-align:left!important;
}
p{
    text-align:left;
}
h6{
    text-align: center;
}
.row{
padding: 10px 80px
}
a:hover{
    color: white;
}


@media (max-width:500px){
    .pricing{
    background-color: #fafafa;
    padding: 10px 30px;
    align-items: center;
    
    }
    .pricing__h1{
        padding: 10px 0px;
        font-size:20px;

    }
   
    .pricing__h3{
        font-size:11px;
        color:black;
        padding: 10px 0px 0px;
        display: block;
        text-align: center;
        align-items: center;
    }
    .pricing__h3__1{
        font-size:11px;
        color:black;
        width: 100%;
        margin-left: 35px;
        margin-right: left;  
        text-align:left; 
    
    }
   .pricing__paragraph{
       width: 100%;
       opacity: 1!important;
       padding: 10px;
       font-size: 11px;
       text-align: center;
       align-items: center;
   } 

   .pricing__touchup{
    width: 100%;
    font-size: 10px!important;
    font-weight: lighter;
    padding-top: -200px;
}


h6{
    text-align: center;
    font-size: 10px;
    padding: 0px;
    line-height: 1.5em;
}
p{
    text-align: center;
    font-size: 10px;
    padding:10px;
}
.row{
    padding:0px;
}

}