.about{
    background-color: #fafafa;
    padding: 10px 180px;
}
#powder_brows_near_me{
    height: 700px;
}

@media (max-width:500px){
    .about{
        background-color: #fafafa;
        padding: 1px ;
        text-align: center;
    }


    .text-black{
        background-color: #fafafa;
        text-align: center;
        font-size: 15px!important;
    }
    .mb-5{
        margin-bottom: 10px!important;
    }

}