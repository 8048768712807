.gallery{
    background-color: #fafafa;
    padding: 10px 30px;
    
}
h2 {
    text-align: center;
    font-weight: bold;

    }


.gallery__text{
text-align: center;
padding-top: 30px;
font-size:50px;
font-weight:bold;
}



@media (max-width:500px){
    .gallery{
    background-color: #fafafa;
    padding:0px;
    margin: 0px;
    align-items: center;

    }
    .gallery__text{
        background-color: #fafafa;
        margin-top: 20px;
        display: inline;
        text-align: center;
        font-size:10px;
      
        }
}