.footer{
    padding: 0.5rem;
    background-color: black;
    color: white;
    text-align: center;
    width: 100%;
    z-index: 1;
  }


  .copyright{
    justify-content: space-evenly;
    padding-right: 25%;  

  }

  .POWERED{
    justify-content: space-evenly;
    padding-left: 25%;  
  }
  .POWERED:hover{
    color: #619c1c;
    text-decoration: none !important;

  }
  .copyright:hover{
    color: #619c1c;
    text-decoration: none !important;
  }

  .copyright:link{
    text-decoration: none; 
  }
  .icon-bar > a:hover{
    color: #eb6d7a;
    
  }
a{
  color:white;
  padding: 0.5rem;

}
.icon-bar{
  padding: 0.5rem;
  margin: 0;
}
@media (max-width:500px){

  .footer{
    padding: 0.5rem;
    background-color: black;
    color: white;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
  .copyright{
    justify-content: space-evenly;
    font-size: xx-small;
    display: flex;
    flex-direction:row;
    padding-right: 2px;
  }
  .POWERED{
    justify-content: space-evenly;
    display: flex;
    flex-direction:row;
    font-size: xx-small;
    padding-left: 10%;
    
  }
  }