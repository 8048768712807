
.header{
    width:100%;
    position: sticky; 
    background-color: #fafafa;
    z-index: 100;
    top: 0;
    box-shadow: 0px 5px 8px -9px  rgb(0, 0, 0, 0.75);
}
.header__home:hover{
    color: #619c1c;
}
.navbar {
    background-color: #fafafa;
    color: rgba(108, 108, 108, 0.5);
    display: flex;
 }

 .header__home{
    outline: none;
    text-decoration: none;
 }
#nav__item:hover{
     color: #619c1c;
 }

.nav-link {
    color: rgba(108, 108, 108, 0.5);
    font-family: 'Proza Libre', sans-serif;
    font-size: medium;
    display: flex;
    padding-right: 20px;
    /* flex: 1; */
    justify-content: flex-end;
}
.navbar:hover{
    color: #619c1c;
}
.img{
    object-fit: contain;
    display: flex;
    justify-content: space-evenly;
    height:70px;
    background-color: transparent;
    padding-right: 30px;  
  }
  @media (max-width:500px){
    .img{
        display: none;
      }   
      
}